import React from 'react';
import '../sidebar/sidebar.css';
import { Link, useNavigate } from 'react-router-dom';
import StrollerIcon from '@mui/icons-material/Stroller';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { MdNotificationsActive, MdCategory } from 'react-icons/md';
import { GiVerticalBanner } from 'react-icons/gi';
import { RiQuestionnaireFill } from 'react-icons/ri';
import { FiLogOut } from 'react-icons/fi';
import { BsDownload, BsFillKeyFill } from 'react-icons/bs';

const SideBar = () => {
  const bearerToken = localStorage.getItem('session-token');
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('session-token');
    localStorage.removeItem('session-id');
    navigate('/');
  };

  return (
    <div className="sidebar">
      <div className="top">
        <span className="logo mt-3">Bank Like a Bank</span>
      </div>
      <hr></hr>
      <div className="center">
        <ul>
          <Link to="/send/notification" className="link">
            <li>
              <MdNotificationsActive size={25} className="icon" />

              <span>Send Notification</span>
            </li>
          </Link>
          <Link to="/questions/wealth" className="link">
            <li>
              <AutoGraphIcon className="icon" />
              <span>Wealth Questions</span>
            </li>
          </Link>
          <Link to="/questions/survey" className="link">
            <li>
              <RiQuestionnaireFill className="icon" size={25} />
              <span>Survey Questions</span>
            </li>
          </Link>

          <Link to="/category" className="link">
            <li>
              <MdCategory size={25} className="icon" />
              <span>Categories</span>
            </li>
          </Link>
          <Link to="/resource" className="link">
            <li>
              <StrollerIcon className="icon" />
              <span>Resource Categories</span>
            </li>
          </Link>
          {/* <Link to="/banner" className="link">
            <li>
              <GiVerticalBanner className="icon" />
              <span>Banners</span>
            </li>
          </Link> */}
          <Link
            to={`${process.env.REACT_APP_API_URL}/user/download?authToken=${bearerToken}`}
            className="link"
            target="_blank"
          >
            <li>
              <BsDownload className="icon" />
              <span>Download Users</span>
            </li>
          </Link>
          <Link to="/password/change" className="link">
            <li>
              <BsFillKeyFill size={25} className="icon" />
              <span>Change Password</span>
            </li>
          </Link>

          <button onClick={() => logout()} className="border-0 bg-white link">
            <FiLogOut className="icon" size={25} />
            <span>Logout</span>
          </button>
        </ul>
      </div>
      <div className="bottom"></div>
    </div>
  );
};

export default SideBar;
