import axios from 'axios';

export const getBanners = async () => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(`${process.env.REACT_APP_API_URL}/banner`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      BasicAuth: process.env.REACT_APP_BASIC_AUTH,
    },
  });
};

export const createBanner = async (banner) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.post(`${process.env.REACT_APP_API_URL}/banner`, banner, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      BasicAuth: process.env.REACT_APP_BASIC_AUTH,
    },
  });
};

export const getBannerById = async (bannerId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/banner/${bannerId}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const deleteBanner = async (bannerId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/banner/${bannerId}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const updateBanner = async (banner, bannerId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.patch(
    `${process.env.REACT_APP_API_URL}/banner/${bannerId}`,
    banner,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};
