import React, { useState } from 'react';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../../../components/navbar/Navbar';
import SideBar from '../../../components/sidebar/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';
import { createLink } from '../../../services/link.service';

const CreateResourceLink = () => {
  const [inputs, setInputs] = useState({
    label: '',
    value: '',
    action: '',
    order: '',
  });

  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(false);
  const { resourceCategoryId } = useParams();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.target.reset();
    if (
      inputs.label === '' ||
      inputs.value === '' ||
      inputs.action === '' ||
      inputs.order === ''
    ) {
      setError(true);
    } else {
      try {
        handleToggle();

        const link = {
          label: inputs.label,
          value: inputs.value,
          action: inputs.action,
          order: inputs.order,
        };

        const create = await createLink(resourceCategoryId, link);
        if (create) {
          const timer = setTimeout(() => {
            handleClose();
            createdQuestionSuccess();
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          const timer = setTimeout(() => {
            handleClose();
            ErrorQuestionShow();
          }, 1000);
          return () => clearTimeout(timer);
        }
      } catch (error) {
        const timer = setTimeout(() => {
          console.log('Something went wrong');
          handleClose();
          ErrorQuestionShow();
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
  };

  const ErrorQuestionShow = () => {
    toast.error('Something went wrong', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const createdQuestionSuccess = () => {
    toast.success('Resource Link Created', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="sendnotify">
      <SideBar />
      <div className="sendnotifyContainer">
        <Navbar />
        <div className="p-3">
          <div className="  d-flex   justify-content-between ">
            <h4 className="my-1">Create Resource Link</h4>
            <Link to={'/link/' + resourceCategoryId}>
              <button className="border-0   text-black rounded px-2 py-1">
                <HiArrowNarrowLeft
                  style={{ color: '#000' }}
                  size={25}
                  className="m-auto"
                />
              </button>
            </Link>
          </div>
          <div className="changing border mt-4 p-3 rounded m-auto col-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 col-6">
                <label>Label</label>
                <input
                  type="text"
                  name="label"
                  className="form-control"
                  value={inputs.label || ''}
                  onChange={handleChange}
                />
                {error && inputs.label === '' ? (
                  <label style={{ color: '#FF0033' }}>
                    Label should not be empty
                  </label>
                ) : (
                  ''
                )}
              </div>
              <div className="mb-3 col-6">
                <label>Value</label>
                <input
                  type="text"
                  name="value"
                  className="form-control"
                  value={inputs.value || ''}
                  onChange={handleChange}
                />
                {error && inputs.value === '' ? (
                  <label style={{ color: '#FF0033' }}>
                    Value should not be empty
                  </label>
                ) : (
                  ''
                )}
              </div>
              <div className="mb-3 col-6">
                <label>Action</label>
                <input
                  type="text"
                  name="action"
                  className="form-control"
                  value={inputs.action || ''}
                  onChange={handleChange}
                />
                {error && inputs.action === '' ? (
                  <label style={{ color: '#FF0033' }}>
                    Action should not be empty
                  </label>
                ) : (
                  ''
                )}
              </div>
              <div className="mb-3 col-6">
                <label>Order</label>
                <input
                  type="number"
                  name="order"
                  className="form-control"
                  value={inputs.order || ''}
                  onChange={handleChange}
                />
                {error && inputs.order === '' ? (
                  <label style={{ color: '#FF0033' }}>
                    Order should not be empty
                  </label>
                ) : (
                  ''
                )}
              </div>

              <div className="d-grid col-2">
                <button
                  style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                  className="border-0 rounded text-white "
                >
                  Create
                </button>
                <Backdrop
                  sx={{
                    color: '#fff',

                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                  onClick={handleClose}
                >
                  <div
                    className=" d-flex py-4  bg-dark flex-column justify-content-center"
                    style={{ width: '240px', height: '90px' }}
                  >
                    <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                    <h6 className="m-auto">Creating Resource Link...</h6>
                  </div>
                </Backdrop>
                <ToastContainer
                  position="top-right"
                  autoClose={2000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  draggable
                  theme="light"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateResourceLink;
