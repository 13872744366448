import React, { useState, useEffect } from 'react';
import { MdOutlineDelete } from 'react-icons/md';
import {
  deleteQuestion,
  getAllQuestions,
} from '../../../services/question.service';
import SideBar from '../../../components/sidebar/SideBar';
import { Link } from 'react-router-dom';
import './listsurveyquestion.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Table } from 'react-bootstrap';
import _ from 'lodash';
import Navbar from '../../../components/navbar/Navbar';
import { Backdrop, CircularProgress } from '@mui/material';
import { BsPencil } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import Pagination from 'react-bootstrap/Pagination';

const SurveyQuestionList = () => {
  const [pageDataa, setPageDataa] = useState([]);
  const [paging, setPaging] = useState(1);
  const [pageCounting, setPageCounting] = useState(0);
  const [open, setOpen] = React.useState(false);
  const customId = 'custom-id-survey';
  const [question, setQuestion] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [show, setShow] = useState(false);

  const handleNext = () => {
    if (paging === pageCounting) return paging;
    setPaging(paging + 1);
  };

  const handlePrevios = () => {
    if (paging === 1) return paging;
    setPaging(paging - 1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleToggle();
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      questions();
    }, 100);
    return () => clearTimeout(timer);
  }, [paging]);

  useEffect(() => {
    const pagedatacount = Math.ceil(question.length / 10);
    setPageCounting(pagedatacount);

    if (paging) {
      const LIMIT = 10;
      const skip = LIMIT * paging;
      const dataskip = question.slice(paging === 1 ? 0 : skip - LIMIT, skip);
      setPageDataa(dataskip);
    }
  }, [question]);

  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const handleDeleteItem = async () => {
    await deleteQuestion(deleteId);
    questions();
    setShow(false);
    showDeletedMessage();
  };

  const questions = async () => {
    try {
      let response = (await getAllQuestions('survey')).data.data;

      // const res = response.map((item) => {
      //   return {
      //     id: item._id,
      //     category: item.category,
      //     order: item.order,
      //     key: item.key,
      //     question: item.question,
      //     status: item.status,
      //   };
      // });

      setQuestion(response);
      handleClose();
    } catch (error) {
      handleClose();
      console.error('something went wrong', error);
      showErrorMessage();
    }
  };

  const showErrorMessage = () => {
    toast.error('Something went wrong', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };

  const showDeletedMessage = () => {
    toast.success('Deleted Successfully', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };

  const handleCloseModal = () => {
    setShow(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="list">
      <SideBar />
      <div className="list-container ">
        <Navbar />

        <div className="p-3 ">
          <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header onHide={handleCloseModal} closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              ARE YOU SURE YOU WANT TO DELETE THIS SURVEY QUESTION ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  backgroundColor: 'red ',
                  border: 'none',
                  outline: 'none',
                }}
                onClick={handleDeleteItem}
              >
                Delete
              </Button>
              <Button variant="primary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="d-flex  justify-content-between">
            <h4 className=" my-auto">Survey Questions</h4>
            <Link to={'/question/create'}>
              <button
                style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                className="border-0 rounded text-white "
              >
                Create Survey Question
              </button>
            </Link>
          </div>

          <div className="border px-3 mt-4 rounded">
            {!pageDataa || question.length === 0 ? (
              'No Results Found...'
            ) : (
              <Table responsive="sm   ">
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Order</th>
                    <th>Question</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {pageDataa.map((item) => (
                    <tr key={item.id}>
                      <td className="tablesurvey">{item.key}</td>
                      <td>{item.order}</td>
                      <td>{item.question}</td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {item.status}
                      </td>
                      <td>
                        {item.status === 'deleted' ? (
                          <></>
                        ) : (
                          <Link to={'/survey/edit/' + item.id}>
                            <button className="border-0   survey-update rounded px-2 py-1">
                              <BsPencil
                                style={{ color: '#000' }}
                                size={18}
                                className="m-auto"
                                title="Edit"
                              />
                            </button>
                          </Link>
                        )}
                        {item.status === 'deleted' ? (
                          <></>
                        ) : (
                          <button
                            onClick={() => handleClickDelete(item.id)}
                            className="border-0   survey-update rounded px-2 py-1"
                          >
                            <MdOutlineDelete
                              style={{ color: '#000' }}
                              size={22}
                              className="m-auto"
                              title="Delete "
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <div>
              {pageDataa.length === 0 ? (
                <></>
              ) : (
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevios}
                    disabled={paging === 1}
                  />
                  {Array(pageCounting)
                    .fill(null)
                    .map((ele, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Pagination.Item
                            active={paging === index + 1 ? true : false}
                            onClick={() => setPaging(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        </React.Fragment>
                      );
                    })}
                  <Pagination.Next
                    onClick={handleNext}
                    disabled={paging === pageCounting}
                  />
                </Pagination>
              )}
            </div>
          </div>
          <Backdrop
            sx={{
              color: '#fff',

              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
            onClick={handleClose}
          >
            <div
              className=" d-flex py-4  bg-dark flex-column justify-content-center"
              style={{ width: '240px', height: '90px' }}
            >
              <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
              <h6 className="m-auto">Loading Survey Questions...</h6>
            </div>
          </Backdrop>

          <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            theme="light"
          />
        </div>
      </div>
    </div>
  );
};

export default SurveyQuestionList;
