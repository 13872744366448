import React, { useState, useEffect } from 'react';
import SideBar from '../../../components/sidebar/SideBar';
import Navbar from '../../../components/navbar/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { Modal, Table, Button } from 'react-bootstrap';
import _ from 'lodash';
import { Backdrop, CircularProgress } from '@mui/material';
import { BsPencil } from 'react-icons/bs';
import Pagination from 'react-bootstrap/Pagination';
import { deleteBanner, getBanners } from '../../../services/banner.service';
import { MdOutlineDelete } from 'react-icons/md';
import { BannerStatus } from '../../../models/banner.model';

const BannerList = () => {
  const [pageDataa, setPageDataa] = useState([]);
  const [paging, setPaging] = useState(1);
  const [pageCounting, setPageCounting] = useState(0);
  const [deleteId, setDeleteId] = useState('');
  const [show, setShow] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [banner, setBanner] = useState([]);
  const customId = 'custom-id-category';

  useEffect(() => {
    const timer = setTimeout(() => {
      handleToggle();
    });
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllBanners();
    }, 100);
    return () => clearTimeout(timer);
  }, [paging]);

  const handleNext = () => {
    if (paging === pageCounting) return paging;
    setPaging(paging + 1);
  };

  const handlePrevios = () => {
    if (paging === 1) return paging;
    setPaging(paging - 1);
  };
  useEffect(() => {
    const pagedatacount = Math.ceil(banner.length / 10);
    setPageCounting(pagedatacount);

    if (paging) {
      const LIMIT = 10;
      const skip = LIMIT * paging;
      const dataskip = banner.slice(paging === 1 ? 0 : skip - LIMIT, skip);
      setPageDataa(dataskip);
    }
  }, [banner]);
  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const handleDeleteItem = async () => {
    await deleteBanner(deleteId);
    getAllBanners();
    setShow(false);
    showDeletedMessage();
  };

  const getAllBanners = async () => {
    try {
      let response = await getBanners();
      setBanner(response.data.data);
      handleClose();
    } catch (error) {
      console.error('Something went wrong', error);
      showErrorMessage();
      handleClose();
    }
  };
  const showErrorMessage = () => {
    toast.error('Something went wrong', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };

  const handleCloseModal = () => {
    setShow(false);
  };
  const showDeletedMessage = () => {
    toast.success('Deleted Successfully', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="list">
      <SideBar />
      <div className="list-container">
        <Navbar />
        <div className="p-3">
          <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header onHide={handleCloseModal} closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              ARE YOU SURE YOU WANT TO DELETE THIS BANNER ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  backgroundColor: 'red ',
                  border: 'none',
                  outline: 'none',
                }}
                onClick={handleDeleteItem}
              >
                Delete
              </Button>
              <Button variant="primary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="d-flex  justify-content-between">
            <h4 className="my-auto">Banners</h4>
            <Link to={'/banner/create'}>
              <button
                style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                className="border-0 rounded text-white "
              >
                Create Banner
              </button>
            </Link>
          </div>
          <div className="border px-3 mt-4 rounded ">
            {!pageDataa || banner.length === 0 ? (
              'No Results Found...'
            ) : (
              <Table responsive="sm">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Click Url</th>
                    <th>Type</th>
                    <th>Page</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {pageDataa.map((item) => (
                    <tr key={item.id}>
                      <td>
                        <img src={`${item.url}`} height={60} width={60} />
                      </td>
                      <td>{item.clickUrl}</td>
                      <td className="text-capitalize">{item.type}</td>
                      <td className="text-capitalize">{item.value}</td>

                      <td>
                        {item.status === BannerStatus.Deleted ? (
                          <></>
                        ) : (
                          <Link to={'/banner/' + item.id}>
                            <button className="border-0   survey-update rounded px-2 py-1">
                              <BsPencil
                                style={{ color: '#000' }}
                                size={18}
                                className="m-auto"
                                title="Edit"
                              />
                            </button>
                          </Link>
                        )}
                        {item.status === 'deleted' ? (
                          <></>
                        ) : (
                          <button
                            onClick={() => handleClickDelete(item.id)}
                            className="border-0   survey-update rounded px-2 py-1"
                          >
                            <MdOutlineDelete
                              style={{ color: '#000' }}
                              size={22}
                              className="m-auto"
                              title="Delete "
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <div>
              {pageDataa.length === 0 ? (
                <></>
              ) : (
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevios}
                    disabled={paging === 1}
                  />
                  {Array(pageCounting)
                    .fill(null)
                    .map((ele, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Pagination.Item
                            active={paging === index + 1 ? true : false}
                            onClick={() => setPaging(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        </React.Fragment>
                      );
                    })}
                  <Pagination.Next
                    onClick={handleNext}
                    disabled={paging === pageCounting}
                  />
                </Pagination>
              )}
            </div>
          </div>
        </div>
        <Backdrop
          sx={{
            color: '#fff',

            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
          onClick={handleClose}
        >
          <div
            className=" d-flex py-4  bg-dark flex-column justify-content-center"
            style={{ width: '240px', height: '90px' }}
          >
            <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
            <h6 className="m-auto">Loading Banners...</h6>
          </div>
        </Backdrop>

        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      </div>
    </div>
  );
};

export default BannerList;
