import React, { useState, useEffect } from 'react';
import { deleteLinkById, getLinks } from '../../../services/link.service';
import SideBar from '../../../components/sidebar/SideBar';
import Navbar from '../../../components/navbar/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useParams } from 'react-router-dom';
import './listlink.css';
import { Button, Modal, Table } from 'react-bootstrap';
import { Backdrop, CircularProgress } from '@mui/material';
import { BsPencil } from 'react-icons/bs';
import { MdOutlineDelete } from 'react-icons/md';
import _ from 'lodash';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import Pagination from 'react-bootstrap/Pagination';
import { getResourceCategoryById } from '../../../services/resourceCategory.service';

const ResourceLinkList = () => {
  const [pageDataa, setPageDataa] = useState([]);
  const [paging, setPaging] = useState(1);
  const [pageCounting, setPageCounting] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [link, setLink] = useState([]);
  const [rCategory, setRCategory] = useState([]);

  const [deleteId, setDeleteId] = useState('');
  const [rdeleteId, setrDeleteId] = useState('');
  const [show, setShow] = useState(false);
  const customId = 'custom-id-link';

  const { resourceCategoryId } = useParams();

  const handleNext = () => {
    if (paging === pageCounting) return paging;
    setPaging(paging + 1);
  };

  const handlePrevios = () => {
    if (paging === 1) return paging;
    setPaging(paging - 1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleToggle();
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    ``;
    const timer = setTimeout(() => {
      getLink();
    }, 100);
    return () => clearTimeout(timer);
  }, [paging]);

  useEffect(() => {
    const pagedatacount = Math.ceil(link.length / 10);
    setPageCounting(pagedatacount);

    if (paging) {
      const LIMIT = 10;
      const skip = LIMIT * paging;
      const dataskip = link.slice(paging === 1 ? 0 : skip - LIMIT, skip);
      setPageDataa(dataskip);
    }
  }, [link]);

  const handleClickDelete = (rId, id) => {
    setrDeleteId(rId);
    setDeleteId(id);
    setShow(true);
  };

  const handleDeleteItem = async () => {
    await deleteLinkById(rdeleteId, deleteId);
    getLink();
    setShow(false);
    showDeleteMessage();
  };

  const getLink = async () => {
    try {
      const response = (await getLinks(resourceCategoryId)).data.data;
      const res = response.map((item) => {
        return {
          id: item._id,
          label: item.label,
          value: item.value,
          order: item.order,
          action: item.action,
          status: item.status,
          resourceCategoryId: item.resourceCategoryId,
        };
      });
      handleClose();
      setLink(res);
      const resourcelabel = (await getResourceCategoryById(resourceCategoryId))
        .data;
      const resource = Object.values(resourcelabel);
      setRCategory(resource[0]);
    } catch (error) {
      console.error('Something Went Wrong', error);
      handleClose();
      showErrorMessage();
    }
  };

  const showErrorMessage = () => {
    toast.error('Something went wrong', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };
  const showDeleteMessage = () => {
    toast.success('Deleted Successfully', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleCloseModal = () => {
    setShow(false);
  };

  return (
    <div className="list">
      <SideBar />
      <div className="list-container">
        <Navbar />

        <div className="p-3">
          <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header onHide={handleCloseModal} closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              ARE YOU SURE YOU WANT TO DELETE RESOURCE LINK ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  backgroundColor: 'red ',
                  border: 'none',
                  outline: 'none',
                }}
                onClick={handleDeleteItem}
              >
                Delete
              </Button>
              <Button variant="primary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="d-flex  justify-content-between">
            <h4 className=" my-auto">Resource Link</h4>

            <div>
              <Link to={'/resource'}>
                <button className="border-0 me-2  text-black rounded px-2 py-1">
                  <HiArrowNarrowLeft
                    style={{ color: '#000' }}
                    size={25}
                    className="m-auto"
                  />
                </button>
              </Link>

              <Link to={`/create/link/${resourceCategoryId}`}>
                <button
                  style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                  className="border-0 rounded text-white "
                >
                  Create Resource Link
                </button>
              </Link>
            </div>
          </div>
          <div className=" my-3 border py-3 px-4 rounded">
            {rCategory && rCategory.label === '' ? (
              <></>
            ) : (
              <h5 className=" my-auto">Label: {rCategory.label}</h5>
            )}
          </div>

          <div className="border px-3 mt-4 rounded ">
            {!pageDataa || link.length === 0 ? (
              'No Results Found'
            ) : (
              <Table>
                <thead>
                  <tr>
                    <th className="" style={{ maxWidth: '250px' }}>
                      Label
                    </th>
                    <th className="col-6">Value</th>
                    <th>Order</th>
                    <th>Action</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {pageDataa.map((item) => (
                    <tr key={item.id}>
                      <td style={{ maxWidth: '250px' }}>{item.label}</td>
                      <td className=" col-6">{item.value}</td>
                      <td>{item.order}</td>
                      <td>{item.action}</td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {item.status}
                      </td>
                      <td>
                        <Link
                          to={
                            '/link/' + item.resourceCategoryId + '/' + item.id
                          }
                        >
                          <button className="border-0   link-update rounded px-2 py-1">
                            <BsPencil
                              style={{ color: '#000' }}
                              size={18}
                              className="m-auto"
                              title="Edit"
                            />
                          </button>
                        </Link>

                        <button
                          onClick={() =>
                            handleClickDelete(item.resourceCategoryId, item.id)
                          }
                          className="border-0   link-update rounded px-2 py-1"
                        >
                          <MdOutlineDelete
                            style={{ color: '#000' }}
                            size={18}
                            className="m-auto"
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <div>
              {pageDataa.length === 0 ? (
                <></>
              ) : (
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevios}
                    disabled={paging === 1}
                  />
                  {Array(pageCounting)
                    .fill(null)
                    .map((ele, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Pagination.Item
                            key={index}
                            active={paging === index + 1 ? true : false}
                            onClick={() => setPaging(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        </React.Fragment>
                      );
                    })}
                  <Pagination.Next
                    onClick={handleNext}
                    disabled={paging === pageCounting}
                  />
                </Pagination>
              )}
            </div>
          </div>
        </div>
        <Backdrop
          sx={{
            color: '#fff',

            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
          onClick={handleClose}
        >
          <div
            className=" d-flex py-4  bg-dark flex-column justify-content-center"
            style={{ width: '260px', height: '90px' }}
          >
            <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
            <h6 className="m-auto"> Loading Resource Category Link...</h6>
          </div>
        </Backdrop>
        <ToastContainer
          position="top-right"
          autoClose={2000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      </div>
    </div>
  );
};

export default ResourceLinkList;
