import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../../../components/navbar/Navbar';
import SideBar from '../../../components/sidebar/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getQuestionById,
  updateQuestionById,
} from '../../../services/question.service';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Backdrop, CircularProgress } from '@mui/material';
import '../../question/update-survey/updatequestion.css';
import { Button } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';
import { RxCross2 } from 'react-icons/rx';

const UpdateSurveyQuestion = () => {
  const [open, setOpen] = React.useState(false);

  const { questionId } = useParams();

  useEffect(() => {
    getQuestion();
  }, []);

  const getQuestion = async () => {
    try {
      let response = (await getQuestionById(questionId)).data;
      setQuestion(response);
      setInputFields(response.dropDownValues);
    } catch (error) {
      console.error('Something went wrong');
    }
  };

  const [question, setQuestion] = useState([]);
  const handleQuestionChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setQuestion((values) => ({ ...values, [name]: value }));
  };

  const handleQuestionSubmit = async (event) => {
    try {
      handleToggle();
      event.preventDefault();
      var updateParams = {};
      if (question.type === 'dropDown') {
        updateParams = {
          question: question.question,
          type: question.type,
          order: question.order,
          dropDownValues: inputFields,
        };
      }

      const updatedSurvey = await updateQuestionById(updateParams, questionId);
      if (updatedSurvey) {
        const timer = setTimeout(() => {
          handleClose();
          showUpdateMessage();
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        const timer = setTimeout(() => {
          handleClose();
          showErrorMessage();
        }, 1000);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      const timer = setTimeout(() => {
        console.log('Something went wrong');
        handleClose();
        showErrorMessage();
      }, 1000);
      return () => clearTimeout(timer);
    }
  };

  const showUpdateMessage = () => {
    toast.success('Survey Question Updated Successully ', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showErrorMessage = () => {
    toast.error('Something Went Wrong', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [inputFields, setInputFields] = useState([
    {
      key: '',
      value: '',
    },
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        key: '',
        value: '',
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="sendnotify">
      <SideBar />
      <div className="sendnotifyContainer">
        <Navbar />
        <div className="p-3">
          <div className="  d-flex   justify-content-between ">
            <h4 className="my-1">Edit Survey Question</h4>
            <Link to="/questions/survey" className=" ">
              <button className="border-0   text-black rounded px-2 py-1">
                <HiArrowNarrowLeft
                  style={{ color: '#000' }}
                  size={25}
                  className="m-auto"
                />
              </button>
            </Link>
          </div>
          <div className=" changing border   mt-4 p-3 rounded m-auto col-12">
            <form onSubmit={handleQuestionSubmit}>
              <div className="mb-3 col-6 ">
                <label>Key</label>
                <input
                  disabled
                  type="text"
                  name="key"
                  className="form-control"
                  value={question.key || ''}
                  onChange={handleQuestionChange}
                />
              </div>

              <div className="mb-3 col-6">
                <label>Question</label>
                <textarea
                  rows={2}
                  type="text"
                  name="question"
                  className="form-control"
                  value={question.question || ''}
                  onChange={handleQuestionChange}
                />
              </div>

              <div className="mb-3 col-6">
                <label>Type</label>
                <select
                  className="form-control "
                  name="category"
                  onChange={handleChange}
                  required
                  style={{ appearance: 'auto' }}
                >
                  <option value="dropDown">Dropdown</option>
                </select>
              </div>

              <div className="">
                {inputFields.map((data, index) => {
                  const { key, value } = data;
                  return (
                    <div className="row my-3" key={index}>
                      <div className="col-3">
                        <div className="form-group">
                          <input
                            type="text"
                            onChange={(evnt) => handleChange(index, evnt)}
                            value={key}
                            name="key"
                            className="form-control"
                            placeholder="Enter Key"
                          />
                        </div>
                      </div>
                      <div className="col-3">
                        <input
                          type="value"
                          onChange={(evnt) => handleChange(index, evnt)}
                          value={value}
                          name="value"
                          className="form-control"
                          placeholder="Enter Value"
                        />
                      </div>
                      <div className="col">
                        {inputFields.length !== 1 ? (
                          <Button
                            className=" border-0 remove-update"
                            onClick={() => removeInputFields(index)}
                          >
                            <RxCross2
                              style={{ color: '#000' }}
                              size={25}
                              className="m-auto"
                              title="Remove "
                            />
                          </Button>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  );
                })}

                <div className="row">
                  <div className="col-sm-12">
                    <Button
                      className=" border-0 add-update"
                      onClick={addInputField}
                    >
                      <AiOutlinePlus
                        style={{ color: '#000' }}
                        size={22}
                        className="m-auto"
                        title="Add Options "
                      />
                    </Button>
                  </div>
                </div>
              </div>

              <div className="mb-3 col-6  mt-3">
                <label>Order</label>
                <input
                  type="number"
                  name="order"
                  className="form-control"
                  value={question.order || ''}
                  onChange={handleQuestionChange}
                />
              </div>
              <div className="d-grid col-2 mt-3">
                <button
                  style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                  className="border-0 rounded text-white "
                >
                  Update
                </button>
              </div>
              <Backdrop
                sx={{
                  color: '#fff',

                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
              >
                <div
                  className=" d-flex py-4  bg-dark flex-column justify-content-center"
                  style={{ width: '240px', height: '90px' }}
                >
                  <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                  <h6 className="m-auto">Updating Survey Question...</h6>
                </div>
              </Backdrop>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="light"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateSurveyQuestion;
