import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import Navbar from '../../../components/navbar/Navbar';
import SideBar from '../../../components/sidebar/SideBar';
import { Backdrop, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { createBanner } from '../../../services/banner.service';
import validator from 'validator';

const CreateBanner = () => {
  const [inputs, setInputs] = useState({
    url: '',
    clickUrl: '',
    value: '',
  });
  const [error, setError] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [images, setImages] = useState({
    selectedImage: '',
  });

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const onFileChange = (e) => {
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);

    setImages(URL.createObjectURL(e.target.files[0]));
    fileReader.onload = (event) => {
      setImages({
        selectedImage: event.target.result,
      });
    };
  };

  const validate = (value) => {
    if (validator.isURL(value)) {
      setErrorMessage('');
      setInputs({
        clickUrl: value,
      });
    } else {
      setErrorMessage('Is Not Valid URL');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      images.selectedImage === '' ||
      inputs.clickUrl === '' ||
      inputs.value === ''
    ) {
      setError(true);
    } else {
      try {
        handleToggle();
        event.target.reset();
        setInputs('');
        setImages('');
        const banner = {
          url: images.selectedImage,
          clickUrl: inputs.clickUrl,
          type: 'page',
          value: inputs.value,
        };
        const bannerCreated = await createBanner(banner);
        if (bannerCreated) {
          const timer = setTimeout(() => {
            handleClose();
            showSentMessage();
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          const timer = setTimeout(() => {
            console.log('Something went wrong', error);
            handleClose();
            showErrorMessage();
          }, 1000);
          return () => clearTimeout(timer);
        }
      } catch (error) {
        const timer = setTimeout(() => {
          console.log('Something went wrong', error);
          handleClose();
          showErrorMessage();
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
  };

  const showSentMessage = () => {
    toast.success('Banner Created Successfully', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showErrorMessage = () => {
    toast.error('Something Went Wrong', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="sendnotify">
      <SideBar />
      <div className="sendnotifyContainer">
        <Navbar />
        <div className="p-3">
          <div className="  d-flex   justify-content-between ">
            <h4 className="my-1">Create Banner</h4>
            <Link to="/banner" className=" ">
              <button className="border-0   text-black rounded px-2 py-1">
                <HiArrowNarrowLeft
                  style={{ color: '#000' }}
                  size={25}
                  className="m-auto"
                />
              </button>
            </Link>
          </div>
          <div className="changing border   mt-4 p-3 rounded m-auto col-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 col-6">
                <label>Image</label>
                <input
                  type="file"
                  name="file"
                  className="form-control"
                  onChange={onFileChange}
                />
                <p className="my-0 py-0">
                  Supported files: image/png, image/jpeg, Ratio: 2:1 [220X110]
                </p>
                {!images.selectedImage ? (
                  ''
                ) : (
                  <img
                    className="mt-2"
                    src={images.selectedImage}
                    alt="img-dtw"
                    height={60}
                    width={60}
                  />
                )}

                {error && images.selectedImage === '' ? (
                  <label style={{ color: '#FF0033' }}>Select the image</label>
                ) : (
                  ''
                )}
              </div>

              <div className="mb-3 col-6">
                <label>Click Url</label>
                <input
                  type="text"
                  name="clickUrl"
                  className="form-control"
                  // value={inputs.clickUrl}
                  onChange={(e) => validate(e.target.value)}
                />

                <span
                  style={{
                    color: '#FF0033',
                  }}
                >
                  {errorMessage}
                </span>
              </div>
              <div className="mb-3 mt-4 col-6">
                <label>Page</label>
                <select
                  className="form-control "
                  name="value"
                  onChange={handleChange}
                  style={{ appearance: 'auto' }}
                >
                  <option value="">Select</option>
                  <option value="links">Links</option>
                  <option value="resources">Resources</option>
                </select>
                {error && inputs.value === '' ? (
                  <label style={{ color: '#FF0033' }}>
                    Page should be selected
                  </label>
                ) : (
                  ''
                )}
              </div>

              <div className="d-grid col-2">
                <button
                  style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                  className="border-0 rounded text-white "
                >
                  Create
                </button>
              </div>
              <Backdrop
                sx={{
                  color: '#fff',

                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
              >
                <div
                  className=" d-flex py-4  bg-dark flex-column justify-content-center"
                  style={{ width: '240px', height: '90px' }}
                >
                  <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                  <h6 className="m-auto">Creating Banner ...</h6>
                </div>
              </Backdrop>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="light"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateBanner;
