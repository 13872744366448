import React, { useState, useEffect } from 'react';
import { getAllQuestions } from '../../../services/question.service';
import SideBar from '../../../components/sidebar/SideBar';
import './listwealthquestion.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import _ from 'lodash';
import { Table } from 'react-bootstrap';
import Navbar from '../../../components/navbar/Navbar';
import { Link } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { BsPencil } from 'react-icons/bs';
import Pagination from 'react-bootstrap/Pagination';

const pageSize = 7;

const WealthQuestionList = () => {
  const [pageDataa, setPageDataa] = useState([]);
  const [paging, setPaging] = useState(1);
  const [pageCounting, setPageCounting] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [question, setQuestion] = useState([]);

  const customId = 'custom-id-wealth';

  const handleNext = () => {
    if (paging === pageCounting) return paging;
    setPaging(paging + 1);
  };

  const handlePrevios = () => {
    if (paging === 1) return paging;
    setPaging(paging - 1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleToggle();
    });
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllQuestion();
    }, 100);
    return () => clearTimeout(timer);
  }, [paging]);

  useEffect(() => {
    const pagedatacount = Math.ceil(question.length / 10);
    setPageCounting(pagedatacount);

    if (paging) {
      const LIMIT = 10;
      const skip = LIMIT * paging;
      const dataskip = question.slice(paging === 1 ? 0 : skip - LIMIT, skip);
      setPageDataa(dataskip);
    }
  }, [question]);
  const getAllQuestion = async () => {
    try {
      let response = (await getAllQuestions('wealth')).data.data;

      const res = response.map((item) => {
        return {
          id: item.id,
          category: item.category,
          order: item.order,
          key: item.key,
          question: item.question,
          status: item.status,
        };
      });
      setQuestion(res);
      handleClose();
    } catch (err) {
      handleClose();
      console.log('something went wrong', err);
      showErrorMessage();
    }
  };

  const showErrorMessage = () => {
    toast.error('Something went wrong', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="list">
      <SideBar />
      <div className="list-container ">
        <Navbar />

        <div className="p-3">
          <h4 className="  mt-1">Wealth Questions</h4>
          <div className="border px-3 mt-4 rounded ">
            {!pageDataa || question.length === 0 ? (
              'No Results Found...'
            ) : (
              <Table responsive="sm  ">
                <thead className="">
                  <tr className="">
                    <th>Key</th>
                    <th>Order</th>
                    <th>Question</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="">
                  {pageDataa.map((item) => (
                    <tr key={item.id}>
                      <td>{item.key}</td>
                      <td>{item.order}</td>
                      <td>{item.question}</td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {item.status}
                      </td>
                      <td className="">
                        <Link to={'/wealth/edit/' + item.id}>
                          <button className="border-0   wealth-update rounded px-2 py-1">
                            <BsPencil
                              style={{ color: '#000' }}
                              size={18}
                              className="m-auto"
                              title="Edit"
                            />
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            <div>
              {pageDataa.length === 0 ? (
                <></>
              ) : (
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevios}
                    disabled={paging === 1}
                  />
                  {Array(pageCounting)
                    .fill(null)
                    .map((ele, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Pagination.Item
                            active={paging === index + 1 ? true : false}
                            onClick={() => setPaging(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        </React.Fragment>
                      );
                    })}
                  <Pagination.Next
                    onClick={handleNext}
                    disabled={paging === pageCounting}
                  />
                </Pagination>
              )}
            </div>
            <Backdrop
              sx={{
                color: '#fff',

                zIndex: (theme) => theme.zIndex.drawer + 1,
              }}
              open={open}
              onClick={handleClose}
            >
              <div
                className=" d-flex py-4  bg-dark flex-column justify-content-center"
                style={{ width: '240px', height: '90px' }}
              >
                <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                <h6 className="m-auto">Loading Wealth Questions...</h6>
              </div>
            </Backdrop>
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={true}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              draggable
              theme="light"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WealthQuestionList;
