import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../../../components/navbar/Navbar';
import SideBar from '../../../components/sidebar/SideBar';
import { getLinkById, updateLinkById } from '../../../services/link.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Backdrop, CircularProgress } from '@mui/material';

const UpdateResourceLink = () => {
  const [open, setOpen] = React.useState(false);
  const { resourceCategoryId, linkId } = useParams();

  useEffect(() => {
    getLink();
  }, []);

  const getLink = async () => {
    let response = (await getLinkById(resourceCategoryId, linkId)).data;
    setInputs(response);
  };
  const [inputs, setInputs] = useState({});
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      handleToggle();
      const linkresource = {
        label: inputs.label,
        value: inputs.value,
        action: inputs.action,
        order: inputs.order,
      };
      const updatedlink = await updateLinkById(
        linkresource,
        resourceCategoryId,
        linkId
      );
      if (updatedlink) {
        const timer = setTimeout(() => {
          handleClose();
          showSentMessage();
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        const timer = setTimeout(() => {
          handleClose();
          showErrorMessage();
        }, 1000);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      const timer = setTimeout(() => {
        console.log('Something went wrong');
        handleClose();
        showErrorMessage();
      }, 1000);
      return () => clearTimeout(timer);
    }
  };

  const showSentMessage = () => {
    toast.success(' Resource Link Updated Successfully ', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showErrorMessage = () => {
    toast.error('Something Went Wrong', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="sendnotify">
      <SideBar />
      <div className="sendnotifyContainer">
        <Navbar />
        <div className="p-3">
          <div className="  d-flex   justify-content-between ">
            <h4 className="my-1">Edit Resource Link</h4>
            <Link to={'/link/' + inputs.resourceCategoryId}>
              <button className="border-0   text-black rounded px-2 py-1">
                <HiArrowNarrowLeft
                  style={{ color: '#000' }}
                  size={25}
                  className="m-auto"
                />
              </button>
            </Link>
          </div>
          <div className="changing border   mt-4 p-3 rounded m-auto col-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 col-6">
                <label>Label</label>
                <input
                  type="text"
                  name="label"
                  className="form-control"
                  value={inputs.label || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-6">
                <label>Value</label>
                <input
                  type="text"
                  name="value"
                  className="form-control"
                  value={inputs.value || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-6">
                <label>Action</label>
                <input
                  type="text"
                  name="action"
                  className="form-control"
                  value={inputs.action || ''}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-6">
                <label>Order</label>
                <input
                  type="number"
                  name="order"
                  className="form-control"
                  value={inputs.order || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="d-grid col-2">
                <button
                  style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                  className="border-0 rounded text-white "
                >
                  Update
                </button>
              </div>
              <Backdrop
                sx={{
                  color: '#fff',

                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
              >
                <div
                  className=" d-flex py-4  bg-dark flex-column justify-content-center"
                  style={{ width: '240px', height: '90px' }}
                >
                  <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                  <h6 className="m-auto">Updating Link Resource...</h6>
                </div>
              </Backdrop>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="light"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateResourceLink;
