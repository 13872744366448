import axios from 'axios';

export const getCategories = async () => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(`${process.env.REACT_APP_API_URL}/category`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      BasicAuth: process.env.REACT_APP_BASIC_AUTH,
    },
  });
};

export const getCategoryById = async (categoryId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/category/${categoryId}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const updateCategoryById = async (category, categoryId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.patch(
    `${process.env.REACT_APP_API_URL}/category/${categoryId}`,
    category,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};
