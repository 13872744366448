import React, { useState } from 'react';
import Navbar from '../../../components/navbar/Navbar';
import SideBar from '../../../components/sidebar/SideBar';
import { createQuestion } from '../../../services/question.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { RxCross2 } from 'react-icons/rx';
import { Backdrop, CircularProgress } from '@mui/material';
import '../../question/create/createquestion.css';
import { Button } from 'react-bootstrap';
import { AiOutlinePlus } from 'react-icons/ai';

function CreateQuestion() {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(false);
  const [question, setQuestion] = useState({
    category: '',
    key: '',
    type: '',
    question: '',
    order: '',
  });

  const handleQuestionChange = (event) => {
    const { name, value } = event.target;
    setQuestion((values) => ({ ...values, [name]: value }));
  };

  const [inputFields, setInputFields] = useState([
    {
      key: '',
      value: '',
    },
  ]);

  const addInputField = () => {
    setInputFields([
      ...inputFields,
      {
        key: '',
        value: '',
      },
    ]);
  };

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);
  };

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  };

  const handleQuestionSubmit = async (e) => {
    e.preventDefault();
    let isErr = false;
    for (let i = 0; i < inputFields.length; i++) {
      if (inputFields[i].key === '' || inputFields[i].value === '') {
        isErr = true;
      }
    }
    if (
      question.key === '' ||
      question.type === '' ||
      question.question === '' ||
      question.order === ''
    ) {
      setError(true);
    } else if (isErr) {
      setError(true);
    } else {
      try {
        handleToggle();
        e.target.reset();
        setQuestion('');

        var createParams = {};
        if (question.type === 'dropDown') {
          createParams = {
            key: question.key,
            question: question.question,
            category: 'survey',
            type: question.type,
            order: question.order,
            dropDownValues: inputFields,
          };
        }

        const create = await createQuestion(createParams);
        if (create) {
          const timer = setTimeout(() => {
            handleClose();
            createdQuestionSuccess();
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          const timer = setTimeout(() => {
            handleClose();
            createdQuestionShow();
          }, 1000);
          return () => clearTimeout(timer);
        }
      } catch (error) {
        const timer = setTimeout(() => {
          handleClose();
          createdQuestionShow();
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
  };

  const createdQuestionShow = () => {
    toast.error('Something went wrong', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const createdQuestionSuccess = () => {
    toast.success('Question Created', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="list">
      <SideBar />
      <div className="list-container">
        <div>
          <Navbar />
          <div className="p-3">
            <div className="  d-flex   justify-content-between ">
              <h4 className="my-1">Create Survey Question</h4>
              <Link to="/questions/survey" className=" ">
                <button className="border-0   text-black rounded px-2 py-1">
                  <HiArrowNarrowLeft
                    style={{ color: '#000' }}
                    size={25}
                    className="m-auto"
                  />
                </button>
              </Link>
            </div>
            <div className=" changing border   mt-4 p-3 rounded m-auto col-12">
              <form onSubmit={handleQuestionSubmit}>
                <div className="mb-3 col-6 ">
                  <label>Key</label>
                  <input
                    type="text"
                    name="key"
                    className="form-control"
                    value={question.key || ''}
                    onChange={handleQuestionChange}
                  />
                  {error && question.key === '' ? (
                    <label style={{ color: '#FF0033' }}>
                      Key should not be empty
                    </label>
                  ) : (
                    ''
                  )}
                </div>

                <div className="mb-3 col-6">
                  <label>Question</label>
                  <textarea
                    rows={2}
                    type="text"
                    name="question"
                    className="form-control"
                    value={question.question || ''}
                    onChange={handleQuestionChange}
                  />
                  {error && question.question === '' ? (
                    <label style={{ color: '#FF0033' }}>
                      Question should not be empty
                    </label>
                  ) : (
                    ''
                  )}
                </div>

                <div className="mb-3 col-6">
                  <label>Type</label>
                  <select
                    className="form-control "
                    name="type"
                    onChange={handleQuestionChange}
                    required
                    style={{ appearance: 'auto' }}
                  >
                    <option value="all">Select Type</option>
                    <option value="dropDown">Dropdown</option>
                  </select>
                  {error && question.type === '' ? (
                    <label style={{ color: '#FF0033' }}>
                      Select the dropdown
                    </label>
                  ) : (
                    ''
                  )}
                </div>

                {question.type === 'dropDown' && (
                  <div className="">
                    <h6>Dropdown values</h6>
                    {inputFields.map((data, index) => {
                      const { key, value } = data;
                      return (
                        <div className="row my-3" key={index}>
                          <div className="col-3 me-1">
                            <div className="form-group">
                              <input
                                type="text"
                                onChange={(evnt) => handleChange(index, evnt)}
                                value={key}
                                name="key"
                                className="form-control"
                                placeholder="Enter Key"
                              />
                              {error && inputFields[index].key === '' ? (
                                <label style={{ color: '#FF0033' }}>
                                  Key is required
                                </label>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                          <div className="col-3 mx-2">
                            <input
                              type="value"
                              onChange={(evnt) => handleChange(index, evnt)}
                              value={value}
                              name="value"
                              className="form-control"
                              placeholder="Enter Value"
                            />
                            {error && inputFields[index].value === '' ? (
                              <label style={{ color: '#FF0033' }}>
                                Value is required
                              </label>
                            ) : (
                              ''
                            )}
                          </div>
                          <div className="col">
                            {inputFields.length !== 1 ? (
                              <Button
                                className=" border-0 remove-create"
                                onClick={() => removeInputFields(index)}
                              >
                                <RxCross2
                                  style={{ color: '#000' }}
                                  size={25}
                                  className="m-auto"
                                  title="Remove "
                                />
                              </Button>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      );
                    })}

                    <div className="row">
                      <div className="col-sm-12">
                        <Button
                          className=" border-0 add-create"
                          onClick={addInputField}
                        >
                          <AiOutlinePlus
                            style={{ color: '#000' }}
                            size={22}
                            className="m-auto"
                            title="Add Options "
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                )}

                <div className="mb-3 col-6  mt-3">
                  <label>Order</label>
                  <input
                    type="number"
                    name="order"
                    className="form-control"
                    value={question.order || ''}
                    onChange={handleQuestionChange}
                  />
                  {error && question.order === '' ? (
                    <label style={{ color: '#FF0033' }}>
                      Order should not be empty
                    </label>
                  ) : (
                    ''
                  )}
                </div>

                <div className="d-grid col-2 mt-3 ">
                  <button
                    style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                    className="border-0 rounded text-white "
                  >
                    Create
                  </button>
                </div>
                <Backdrop
                  sx={{
                    color: '#fff',

                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                  onClick={handleClose}
                >
                  <div
                    className=" d-flex py-4  bg-dark flex-column justify-content-center"
                    style={{ width: '240px', height: '90px' }}
                  >
                    <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                    <h6 className="m-auto">Creating Survey Question...</h6>
                  </div>
                </Backdrop>
                <ToastContainer
                  position="top-right"
                  autoClose={2000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  draggable
                  theme="light"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CreateQuestion;
