import axios from 'axios';

export const getResourceCategories = async () => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(`${process.env.REACT_APP_API_URL}/resource-category`, {
    headers: {
      Authorization: `Bearer ${bearerToken}`,
      BasicAuth: process.env.REACT_APP_BASIC_AUTH,
    },
  });
};

export const createResourceCategory = async (resourceCategory) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/resource-category`,
    resourceCategory,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const getResourceCategoryById = async (resourceCategoryId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/resource-category/${resourceCategoryId}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const deleteResourceCategory = async (resourceCategoryId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/resource-category/${resourceCategoryId}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const updateResourceCategory = async (
  resourceCategory,
  resourceCategoryId
) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.patch(
    `${process.env.REACT_APP_API_URL}/resource-category/${resourceCategoryId}`,
    resourceCategory,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};
