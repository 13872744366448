import React, { useState, useEffect } from 'react';
import SideBar from '../../../components/sidebar/SideBar';
import Navbar from '../../../components/navbar/Navbar';
import { getCategories } from '../../../services/category.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import './listcategory.css';
import { Table } from 'react-bootstrap';
import _ from 'lodash';
import { Backdrop, CircularProgress } from '@mui/material';
import { BsPencil } from 'react-icons/bs';
import Pagination from 'react-bootstrap/Pagination';

const pageSize = 3;

const CategoryList = () => {
  const [pageDataa, setPageDataa] = useState([]);
  const [paging, setPaging] = useState(1);
  const [pageCounting, setPageCounting] = useState(0);

  const [open, setOpen] = React.useState(false);
  const [categories, setCategories] = useState([]);
  const customId = 'custom-id-category';

  useEffect(() => {
    const timer = setTimeout(() => {
      handleToggle();
    });
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getAllCategory();
    }, 100);
    return () => clearTimeout(timer);
  }, [paging]);

  const handleNext = () => {
    if (paging === pageCounting) return paging;
    setPaging(paging + 1);
  };

  const handlePrevios = () => {
    if (paging === 1) return paging;
    setPaging(paging - 1);
  };
  useEffect(() => {
    const pagedatacount = Math.ceil(categories.length / 10);
    setPageCounting(pagedatacount);

    if (paging) {
      const LIMIT = 10;
      const skip = LIMIT * paging;
      const dataskip = categories.slice(paging === 1 ? 0 : skip - LIMIT, skip);
      setPageDataa(dataskip);
    }
  }, [categories]);

  const getAllCategory = async () => {
    try {
      let response = await getCategories();
      setCategories(response.data.data);
      handleClose();
    } catch (error) {
      console.error('Something went wrong', error);
      showErrorMessage();
      handleClose();
    }
  };

  const showErrorMessage = () => {
    toast.error('Something went wrong', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="list">
      <SideBar />
      <div className="list-container">
        <Navbar />
        <div className="p-3">
          <h4 className="mt-1">Categories</h4>
          <div className="border px-3 mt-4 rounded ">
            {!pageDataa || categories.length === 0 ? (
              'No Results Found...'
            ) : (
              <Table responsive="sm ">
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Type</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {pageDataa.map((item) => (
                    <tr key={item.id}>
                      <td>{item.label}</td>
                      <td className="text-capitalize">{item.type}</td>

                      <td className="">
                        <Link to={'/category/' + item.id}>
                          <button className="border-0   category-edit rounded px-2 py-1">
                            <BsPencil
                              style={{ color: '#000' }}
                              size={18}
                              className="m-auto"
                              title="Edit"
                            />
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <div>
              {pageDataa.length === 0 ? (
                <></>
              ) : (
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevios}
                    disabled={paging === 1}
                  />
                  {Array(pageCounting)
                    .fill(null)
                    .map((ele, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Pagination.Item
                            active={paging === index + 1 ? true : false}
                            onClick={() => setPaging(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        </React.Fragment>
                      );
                    })}
                  <Pagination.Next
                    onClick={handleNext}
                    disabled={paging === pageCounting}
                  />
                </Pagination>
              )}
            </div>
          </div>
        </div>
        <Backdrop
          sx={{
            color: '#fff',

            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={open}
          onClick={handleClose}
        >
          <div
            className=" d-flex py-4  bg-dark flex-column justify-content-center"
            style={{ width: '240px', height: '90px' }}
          >
            <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
            <h6 className="m-auto">Loading Category...</h6>
          </div>
        </Backdrop>

        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          theme="light"
        />
      </div>
    </div>
  );
};

export default CategoryList;
