import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { Backdrop, CircularProgress } from '@mui/material';
import Navbar from '../../../components/navbar/Navbar';
import SideBar from '../../../components/sidebar/SideBar';
import {
  getCategoryById,
  updateCategoryById,
} from '../../../services/category.service';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { HiArrowNarrowLeft } from 'react-icons/hi';

const UpdateCategory = () => {
  const [open, setOpen] = React.useState(false);
  const { categoryId } = useParams();

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    let response = (await getCategoryById(categoryId)).data;
    setLab(response);
    setDesc(response);
  };

  const [lab, setLab] = useState({
    label: '',
  });

  const [desc, setDesc] = useState({
    description: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLab((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setDesc({ ...desc, description: data });
  };

  const handleSubmit = async (event) => {
    handleToggle();
    try {
      event.preventDefault();
      const category = {
        label: lab.label,
        description: desc.description,
      };
      const updatedCategory = await updateCategoryById(category, categoryId);
      if (updatedCategory) {
        const timer = setTimeout(() => {
          handleClose();
          showSentMessage();
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        const timer = setTimeout(() => {
          handleClose();
          showErrorMessage();
        }, 1000);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      const timer = setTimeout(() => {
        console.log('Something went wrong', error);
        handleClose();
        showErrorMessage();
      }, 1000);
      return () => clearTimeout(timer);
    }
  };

  const showSentMessage = () => {
    toast.success('Category Updated Successully ', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showErrorMessage = () => {
    toast.error('Something Went Wrong', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div className="sendnotify">
      <SideBar />
      <div className="sendnotifyContainer">
        <Navbar />
        <div className="p-3">
          <div className="  d-flex   justify-content-between ">
            <h4 className="my-auto">Edit Category</h4>
            <Link to="/category" className=" ">
              <button className="border-0   text-black rounded px-2 py-1">
                <HiArrowNarrowLeft
                  style={{ color: '#000' }}
                  size={25}
                  className="m-auto"
                />
              </button>
            </Link>
          </div>
          <div className="changing border   mt-4 p-3 rounded m-auto col-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 col-8">
                <label>Label</label>
                <input
                  type="text"
                  name="label"
                  className="form-control"
                  value={lab.label}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-3 col-8">
                <label>Description</label>
                <CKEditor
                  editor={ClassicEditor}
                  name="description"
                  config={{
                    removePlugins: ['EasyImage', 'ImageUpload', 'MediaEmbed'],

                    heading: {
                      options: [
                        {
                          model: 'paragraph',
                          title: 'Paragraph',
                          class: 'ck-heading_paragraph',
                        },
                        {
                          model: 'heading1',
                          view: 'h1',
                          title: 'Heading 1',
                          class: 'ck-heading_heading1',
                        },
                        {
                          model: 'heading2',
                          view: 'h2',
                          title: 'Heading 2',
                          class: 'ck-heading_heading2',
                        },
                        {
                          model: 'heading3',
                          view: 'h3',
                          title: 'Heading 3',
                          class: 'ck-heading_heading3',
                        },
                        {
                          model: 'heading4',
                          view: 'h4',
                          title: 'Heading 4',
                          class: 'ck-heading_heading4',
                        },
                        {
                          model: 'heading5',
                          view: 'h5',
                          title: 'Heading 5',
                          class: 'ck-heading_heading5',
                        },
                        {
                          model: 'heading6',
                          view: 'h6',
                          title: 'Heading 6',
                          class: 'ck-heading_heading6',
                        },
                      ],
                    },
                  }}
                  data={desc.description}
                  onChange={handleEditorChange}
                />
              </div>
              <div className="d-grid col-2">
                <button
                  style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                  className="border-0 rounded text-white "
                >
                  Update
                </button>
              </div>
              <Backdrop
                sx={{
                  color: '#fff',

                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
              >
                <div
                  className=" d-flex py-4  bg-dark flex-column justify-content-center"
                  style={{ width: '240px', height: '90px' }}
                >
                  <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                  <h6 className="m-auto">Updating Category...</h6>
                </div>
              </Backdrop>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="light"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateCategory;
