import axios from 'axios';
import React, { useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import SideBar from '../../components/sidebar/SideBar';
import './sendnotification.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';

const SendNotification = () => {
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState(false);
  const [inputs, setInputs] = useState({
    category: 'all',
    title: '',
    message: '',
  });

  const bearerToken = localStorage.getItem('session-token');

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.target.reset();
    if (inputs.title === '' || inputs.message === '') {
      setError(true);
    } else {
      try {
        handleToggle();
        let data = {};
        if (inputs.category !== 'all') {
          data = {
            category: inputs.category,
            title: inputs.title,
            message: inputs.message,
          };
        } else {
          data = {
            title: inputs.title,
            message: inputs.message,
            allUsers: true,
          };
        }

        const notify = await axios.post(
          `${process.env.REACT_APP_API_URL}/notification`,
          data,
          {
            headers: {
              Authorization: `Bearer ${bearerToken}`,
              BasicAuth: process.env.REACT_APP_BASIC_AUTH,
            },
          }
        );

        if (notify) {
          const timer = setTimeout(() => {
            showSentMessage();
            handleClose();
          }, 1000);
          return () => clearTimeout(timer);
        } else {
          const timer = setTimeout(() => {
            handleClose();
          }, 1000);
          return () => clearTimeout(timer);
        }
      } catch (error) {
        const timer = setTimeout(() => {
          console.log('Something went wrong', error);
          showErrorMessage();
          handleClose();
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
  };

  const showSentMessage = () => {
    toast.success('Notification Sent', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showErrorMessage = () => {
    toast.error('Something Went Wrong', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="sendnotify">
      <SideBar />
      <div className="sendnotifyContainer">
        <Navbar />
        <div className="p-3">
          <h4 className="mt-1"> Send Notification</h4>
          <div className="changing border mt-4 p-3 rounded m-auto col-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 mt-4 col-6">
                <label>Category</label>
                <select
                  className="form-control "
                  name="category"
                  onChange={handleChange}
                  required
                  style={{ appearance: 'auto' }}
                >
                  <option value="all">All</option>
                  <option value="red">Red</option>
                  <option value="yellow">Yellow</option>
                  <option value="yellow">Green</option>
                  <option value="discoveryZone">Discovery Zone</option>
                </select>
              </div>

              <div className="mb-3 col-6">
                <label>Title</label>
                <input
                  type="text"
                  name="title"
                  className="form-control rounded"
                  placeholder="Enter Title"
                  onChange={handleChange}
                />
                {error && inputs.title === '' ? (
                  <label style={{ color: '#FF0033' }}>
                    Title should not be empty
                  </label>
                ) : (
                  ''
                )}
              </div>

              <div className="mb-3 col-6">
                <label>Message</label>
                <textarea
                  type="text"
                  rows={4}
                  name="message"
                  className="form-control "
                  placeholder="Enter Message"
                  onChange={handleChange}
                />
                {error && inputs.message === '' ? (
                  <label style={{ color: '#FF0033' }}>
                    Message should not be empty
                  </label>
                ) : (
                  ''
                )}
              </div>

              <div className="d-grid col-2">
                <button type="submit" className="btn btn-primary">
                  Send
                </button>

                <Backdrop
                  sx={{
                    color: '#fff',

                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                  onClick={handleClose}
                >
                  <div
                    className=" d-flex py-4  bg-dark flex-column justify-content-center"
                    style={{ width: '240px', height: '90px' }}
                  >
                    <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                    <h6 className="m-auto">Sending Notification...</h6>
                  </div>
                </Backdrop>
                <ToastContainer
                  position="top-right"
                  autoClose={2000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  draggable
                  theme="light"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendNotification;
