import React, { useState, useEffect } from 'react';
import {
  deleteResourceCategory,
  getResourceCategories,
} from '../../../services/resourceCategory.service';
import { Link } from 'react-router-dom';
import './listresourcecategory.css';
import SideBar from '../../../components/sidebar/SideBar';
import Navbar from '../../../components/navbar/Navbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';
import { Modal, Table, Button } from 'react-bootstrap';
import _ from 'lodash';
import { MdOutlineDelete } from 'react-icons/md';
import { BsPencil } from 'react-icons/bs';
import Pagination from 'react-bootstrap/Pagination';

const ResourceCategoryList = () => {
  const [pageDataa, setPageDataa] = useState([]);
  const [paging, setPaging] = useState(1);
  const [pageCounting, setPageCounting] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [resourceCategory, setResourceCategories] = useState([]);
  const [deleteId, setDeleteId] = useState('');
  const [show, setShow] = useState(false);

  const customId = 'custom-id-resourcecategory';

  const handleNext = () => {
    if (paging === pageCounting) return paging;
    setPaging(paging + 1);
  };

  const handlePrevios = () => {
    if (paging === 1) return paging;
    setPaging(paging - 1);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      handleToggle();
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      getResourceCategory();
    }, 100);
    return () => clearTimeout(timer);
  }, [paging]);

  useEffect(() => {
    const pagedatacount = Math.ceil(resourceCategory.length / 10);
    setPageCounting(pagedatacount);

    if (paging) {
      const LIMIT = 10;
      const skip = LIMIT * paging;
      const dataskip = resourceCategory.slice(
        paging === 1 ? 0 : skip - LIMIT,
        skip
      );
      setPageDataa(dataskip);
    }
  }, [resourceCategory]);

  const handleClickDelete = (id) => {
    setDeleteId(id);
    setShow(true);
  };

  const handleDeleteItem = async () => {
    await deleteResourceCategory(deleteId);
    getResourceCategory();
    setShow(false);
    showDeleteMessage();
  };

  const getResourceCategory = async () => {
    try {
      let response = await getResourceCategories();
      setResourceCategories(response.data.data);
      handleClose();
    } catch (error) {
      console.error('Something went wrong', error);
      handleClose();
      showErrorMessage();
    }
  };

  const showErrorMessage = () => {
    toast.error('Something went wrong', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };
  const showDeleteMessage = () => {
    toast.success('Deleted Successfully', {
      position: toast.POSITION.TOP_CENTER,
      toastId: customId,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleCloseModal = () => {
    setShow(false);
  };

  return (
    <div className="list">
      <SideBar />
      <div className="list-container">
        <Navbar />

        <div className="p-3">
          <Modal show={show} onHide={handleCloseModal}>
            <Modal.Header onHide={handleCloseModal} closeButton>
              <Modal.Title>Confirm</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              ARE YOU SURE YOU WANT TO DELETE THIS RESOURCE CATEGORY ?
            </Modal.Body>
            <Modal.Footer>
              <Button
                style={{
                  backgroundColor: 'red ',
                  border: 'none',
                  outline: 'none',
                }}
                onClick={handleDeleteItem}
              >
                Delete
              </Button>
              <Button variant="primary" onClick={handleCloseModal}>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          <div className="d-flex  justify-content-between">
            <h4 className="my-auto"> Resource Categories </h4>
            <Link to={'/resource/create'}>
              <button
                style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                className="border-0 rounded text-white "
              >
                Create Resource Category
              </button>
            </Link>
          </div>

          <div className="border px-3 mt-4 rounded ">
            {!pageDataa || resourceCategory.length === 0 ? (
              'No Results Found...'
            ) : (
              <Table>
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Order</th>
                    <th>Status</th>
                    <th>Category Link</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {pageDataa.map((item) => (
                    <tr key={item.id}>
                      <td className="col-5">{item.label}</td>
                      <td>{item.order}</td>
                      <td style={{ textTransform: 'capitalize' }}>
                        {item.status}
                      </td>
                      <td>
                        {item.status === 'deleted' ? (
                          <></>
                        ) : (
                          <Link to={'/link/' + item.id} item={item}>
                            Resource Category Link
                          </Link>
                        )}
                      </td>
                      <td>
                        {item.status === 'deleted' ||
                        item.label === 'General' ? (
                          <></>
                        ) : (
                          <Link to={'/resource/' + item.id}>
                            <button className="border-0  resource-update  rounded px-2 py-1">
                              <BsPencil
                                style={{ color: '#000' }}
                                size={18}
                                className="m-auto"
                              />
                            </button>
                          </Link>
                        )}

                        {item.status === 'deleted' ||
                        item.label === 'General' ? (
                          <></>
                        ) : (
                          <button
                            onClick={() => handleClickDelete(item.id)}
                            className="border-0  resource-update rounded px-2 py-1"
                          >
                            <MdOutlineDelete
                              style={{ color: '#000' }}
                              size={18}
                              className="m-auto"
                            />
                          </button>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            <div>
              {pageDataa.length === 0 ? (
                <></>
              ) : (
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevios}
                    disabled={paging === 1}
                  />
                  {Array(pageCounting)
                    .fill(null)
                    .map((ele, index) => {
                      return (
                        <React.Fragment key={index}>
                          <Pagination.Item
                            active={paging === index + 1 ? true : false}
                            onClick={() => setPaging(index + 1)}
                          >
                            {index + 1}
                          </Pagination.Item>
                        </React.Fragment>
                      );
                    })}
                  <Pagination.Next
                    onClick={handleNext}
                    disabled={paging === pageCounting}
                  />
                </Pagination>
              )}
            </div>
          </div>
          <Backdrop
            sx={{
              color: '#fff',

              zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
            open={open}
            onClick={handleClose}
          >
            <div
              className=" d-flex py-4  bg-dark flex-column justify-content-center"
              style={{ width: '260px', height: '90px' }}
            >
              <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
              <h6 className="m-auto"> Loading Resource Category...</h6>
            </div>
          </Backdrop>

          <ToastContainer
            position="top-right"
            autoClose={1000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            theme="light"
          />
        </div>
      </div>
    </div>
  );
};

export default ResourceCategoryList;
