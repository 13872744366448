import axios from 'axios';

export const getLinks = async (rCategoryId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/resource-category/${rCategoryId}/link`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const getLinkById = async (rCategoryId, linkId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/resource-category/${rCategoryId}/link/${linkId}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const createLink = async (rCategoryId, link) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/resource-category/${rCategoryId}/link`,
    link,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const deleteLinkById = async (rCategoryId, linkId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/resource-category/${rCategoryId}/link/${linkId}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const updateLinkById = async (link, rCategoryId, linkId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.patch(
    `${process.env.REACT_APP_API_URL}/resource-category/${rCategoryId}/link/${linkId}`,
    link,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};
