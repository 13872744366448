import { Route, Routes } from 'react-router-dom';
import CategoryList from './pages/category/list/ListCategory';
import ChangePassword from './pages/change-password/ChangePassword';
import Dashboard from './pages/dashboard/Dashboard';
import Login from './pages/login/Login';
import ResourceCategoryList from './pages/resourcecategory/list/ListResourceCategory';
import ResourceLinkList from './pages/link/list/ListLink';
import SendNotification from './pages/send-notification/SendNotification';
import SurveyQuestionList from './pages/question/survey-list/ListSurveyQuestion';
import WealthQuestionList from './pages/question/wealth-list/listwealthquestion';
import CreateQuestion from './pages/question/create/CreateQuestion';
import UpdateCategory from './pages/category/update/UpdateCategory';
import UpdateResourceCategory from './pages/resourcecategory/update/UpdateResourceCategory';
import UpdateResourceLink from './pages/link/update/UpdateLink';
import CreateResourceLink from './pages/link/create/CreateLink';
import CreateResourceCategory from './pages/resourcecategory/create/CreateResourceCategory';
import UpdateSurveyQuestion from './pages/question/update-survey/UpdateQuestion';
import UpdateWealthQuestion from './pages/question/update-wealth/UpdateQuestion';
import CreateLink from '../src/pages/link/create/CreateLink';
import ProtectedRoute from './components/ProtectedRoute';
import '../src/App.css';
import BannerList from './pages/banner/list/ListBanner';
import CreateBanner from './pages/banner/create/CreateBanner';
import UpdateBanner from './pages/banner/update/UpdateBanner';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/">
          <Route index element={<Login />} />
          <Route
            path="/dashboard"
            element={<ProtectedRoute Component={Dashboard} />}
          />
          <Route
            path="/password/change"
            element={<ProtectedRoute Component={ChangePassword} />}
          />
          <Route
            path="/send/notification"
            element={<ProtectedRoute Component={SendNotification} />}
          />
          <Route
            path="/questions/survey"
            element={<ProtectedRoute Component={SurveyQuestionList} />}
          />

          <Route
            path="/create/link/:resourceCategoryId"
            element={<ProtectedRoute Component={CreateLink} />}
          />
          <Route
            path="/survey/edit/:questionId"
            element={<ProtectedRoute Component={UpdateSurveyQuestion} />}
          />
          <Route
            path="/questions/wealth"
            element={<ProtectedRoute Component={WealthQuestionList} />}
          />
          <Route
            path="/wealth/edit/:questionId"
            element={<ProtectedRoute Component={UpdateWealthQuestion} />}
          />
          <Route
            path="/question/create"
            element={<ProtectedRoute Component={CreateQuestion} />}
          />
          <Route
            path="/category/:categoryId"
            element={<ProtectedRoute Component={UpdateCategory} />}
          />
          <Route
            path="/category"
            element={<ProtectedRoute Component={CategoryList} />}
          />
          <Route
            path="/resource"
            element={<ProtectedRoute Component={ResourceCategoryList} />}
          />
          <Route
            path="/resource/create"
            element={<ProtectedRoute Component={CreateResourceCategory} />}
          />
          <Route
            path="/resource/:resourceCategoryId"
            element={<ProtectedRoute Component={UpdateResourceCategory} />}
          />
          <Route
            path="/link/:resourceCategoryId"
            element={<ProtectedRoute Component={ResourceLinkList} />}
          />
          <Route
            path="/link/:resouceCategoryId/create"
            element={<ProtectedRoute Component={CreateResourceLink} />}
          />
          <Route
            path="/link/:resourceCategoryId/:linkId"
            element={<ProtectedRoute Component={UpdateResourceLink} />}
          />
        </Route>

        <Route
          path="/banner"
          element={<ProtectedRoute Component={BannerList} />}
        />
        <Route
          path="/banner/create"
          element={<ProtectedRoute Component={CreateBanner} />}
        />
        <Route
          path="/banner/:bannerId"
          element={<ProtectedRoute Component={UpdateBanner} />}
        />
      </Routes>
    </div>
  );
}

export default App;
