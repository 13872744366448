import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Navbar from '../../../components/navbar/Navbar';
import SideBar from '../../../components/sidebar/SideBar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HiArrowNarrowLeft } from 'react-icons/hi';
import { Backdrop, CircularProgress } from '@mui/material';
import { getBannerById, updateBanner } from '../../../services/banner.service';
const UpdateBanner = () => {
  const [open, setOpen] = React.useState(false);
  const [inputs, setInputs] = useState({});
  const [images, setImages] = useState({
    selectedImage: '',
  });

  const { bannerId } = useParams();
  useEffect(() => {
    getBanner();
  }, []);

  const getBanner = async () => {
    let response = (await getBannerById(bannerId)).data;
    setInputs(response);
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const onFileChange = (e) => {
    let files = e.target.files;
    let fileReader = new FileReader();
    fileReader.readAsDataURL(files[0]);
    setImages(URL.createObjectURL(e.target.files[0]));
    fileReader.onload = (event) => {
      setImages({
        selectedImage: event.target.result,
      });
    };
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      handleToggle();
      const banner = {
        url: images.selectedImage ? images.selectedImage : inputs.url,
        clickUrl: inputs.clickUrl,
        type: inputs.type,
        value: inputs.value,
      };

      const updatedBanner = await updateBanner(banner, bannerId);
      if (updatedBanner) {
        const timer = setTimeout(() => {
          handleClose();
          showSentMessage();
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        const timer = setTimeout(() => {
          handleClose();
          showErrorMessage();
        }, 1000);
        return () => clearTimeout(timer);
      }
    } catch (error) {
      const timer = setTimeout(() => {
        console.log('Something went wrong');
        handleClose();
        showErrorMessage();
      }, 1000);
      return () => clearTimeout(timer);
    }
  };

  const showSentMessage = () => {
    toast.success('Banner Updated Successfully ', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showErrorMessage = () => {
    toast.error('Something Went Wrong', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="sendnotify">
      <SideBar />
      <div className="sendnotifyContainer">
        <Navbar />
        <div className="p-3">
          <div className="  d-flex   justify-content-between ">
            <h4 className="my-1">Edit Banner</h4>
            <Link to={'/banner/'}>
              <button className="border-0   text-black rounded px-2 py-1">
                <HiArrowNarrowLeft
                  style={{ color: '#000' }}
                  size={25}
                  className="m-auto"
                />
              </button>
            </Link>
          </div>
          <div className="changing border   mt-4 p-3 rounded m-auto col-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 col-6">
                <label>Image</label>
                <input
                  type="file"
                  name="file"
                  src={`${inputs.url}`}
                  className="form-control"
                  onChange={onFileChange}
                />
                <p>
                  Supported files: image/png, image/jpeg, Ratio: 2:1 [220X110]
                </p>
                {!images.selectedImage && inputs.url ? (
                  <img
                    className="my-2"
                    src={`${inputs.url}`}
                    alt="img-dtw"
                    height={60}
                    width={60}
                  />
                ) : (
                  <img
                    className="my-2"
                    src={images.selectedImage}
                    height={60}
                    width={60}
                  />
                )}
              </div>

              <div className="mb-3 col-6">
                <label>Click Url</label>
                <input
                  type="text"
                  name="clickUrl"
                  className="form-control"
                  value={inputs.clickUrl || ''}
                  onChange={handleChange}
                />
              </div>

              <div className="mb-3 mt-4 col-6">
                <label>Page</label>
                <select
                  className="form-control "
                  name="value"
                  onChange={handleChange}
                  style={{ appearance: 'auto' }}
                  value={inputs.value || ''}
                >
                  <option value="">Select</option>
                  <option value="links">Links</option>
                  <option value="resources">Resources</option>
                </select>
              </div>

              <div className="d-grid col-2">
                <button
                  style={{ backgroundColor: '#0B5ED7', padding: '7px 10px' }}
                  className="border-0 rounded text-white "
                >
                  Update
                </button>
              </div>
              <Backdrop
                sx={{
                  color: '#fff',

                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
              >
                <div
                  className=" d-flex py-4  bg-dark flex-column justify-content-center"
                  style={{ width: '240px', height: '90px' }}
                >
                  <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                  <h6 className="m-auto">Updating Banner ...</h6>
                </div>
              </Backdrop>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="light"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateBanner;
