import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../login/login.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';
import Logo from '../../images/logo.png';

const Login = () => {
  const [open, setOpen] = React.useState(false);
  const [inputs, setInputs] = useState({
    username: '',
    password: '',
  });
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs.username === '' || inputs.password === '') {
      setError(true);
    } else {
      try {
        handleToggle();
        const admin = await axios.post(
          `${process.env.REACT_APP_API_URL}/admin/login`,
          inputs,
          {
            headers: {
              BasicAuth: process.env.REACT_APP_BASIC_AUTH,
            },
          }
        );

        if (admin) {
          navigate('/send/notification');
          showsucessMessage();
        }
        localStorage.setItem('session-token', admin.data.token);
        localStorage.setItem('user-id', admin.data.id);
      } catch (err) {
        console.log('Something went wrong', err);
        handleClose();
        showErrorMessage();
      }
    }
  };

  const showErrorMessage = () => {
    toast.error(' Invalid Credentials', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showsucessMessage = () => {
    toast.success(' Logged In Successfully', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div
      className="  d-flex  loginContainer  justify-content-center align-items-center"
      style={{ height: '100vh', width: '100%' }}
    >
      <div className="d-flex row loginSize shadow rounded bg-white ">
        <div className=" d-flex justify-content-center align-items-center col ">
          <img className="img-fluid imglog " src={Logo} alt="Login" />
        </div>
        <div className=" logincont my-auto   py-4  px-3 mx-2 rounded col">
          <form className="" onSubmit={handleSubmit}>
            <h2 className="my-4   fw-bold">Sign In</h2>
            <div className="  my-4 col-10">
              <label className=" fw-bold my-1">User Name</label>
              <input
                type="text"
                className="form-control py-1 rounded "
                placeholder="Enter username"
                name="username"
                onChange={handleChange}
              />
              {error && inputs.username === '' ? (
                <label style={{ color: '#FF0033' }}>Username is required</label>
              ) : (
                ''
              )}
            </div>
            <div className="  my-4 col-10">
              <label className=" fw-bold my-1">Password</label>
              <input
                type="password"
                className="form-control py-1  rounded "
                placeholder="Enter password"
                name="password"
                onChange={handleChange}
              />
              {error && inputs.password === '' ? (
                <label style={{ color: '#FF0033' }}>Password is required</label>
              ) : (
                ''
              )}
            </div>

            <div className="d-grid submitbtn my-5 col-10">
              <button
                type="submit"
                value="Submit"
                className="btn  py-2 rounded border shadow"
              >
                Submit
              </button>
              <Backdrop
                sx={{
                  color: '#fff',

                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={open}
                onClick={handleClose}
              >
                <div
                  className=" d-flex py-4  bg-dark flex-column justify-content-center"
                  style={{ width: '240px', height: '90px' }}
                >
                  <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                  <h6 className="m-auto">Logging...</h6>
                </div>
              </Backdrop>
              <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="light"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
