import React from 'react';
import Navbar from '../../components/navbar/Navbar';
import SideBar from '../../components/sidebar/SideBar';
import '../dashboard/dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <SideBar />
      <div className="dashboardContainer">
        <Navbar />
        <div className="p-3">
          <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h4 mb-0 text-gray-800">Dashboard</h1>
          </div>
          <div className="row">
            <div className="col col-lg-6">
              <div className="  mb-4">
                <div className="">This is Dashboard page.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
