import axios from 'axios';

export const getAllQuestions = async (categoryType) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/question?category=${categoryType}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const getQuestionById = async (questionId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.get(
    `${process.env.REACT_APP_API_URL}/question/${questionId}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const createQuestion = async (question) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/question`,
    question,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const deleteQuestion = async (questionId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.delete(
    `${process.env.REACT_APP_API_URL}/question/${questionId}`,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};

export const updateQuestionById = async (question, questionId) => {
  const bearerToken = localStorage.getItem('session-token');
  return await axios.patch(
    `${process.env.REACT_APP_API_URL}/question/${questionId}`,
    question,
    {
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        BasicAuth: process.env.REACT_APP_BASIC_AUTH,
      },
    }
  );
};
