import axios from 'axios';
import React, { useState } from 'react';
import Navbar from '../../components/navbar/Navbar';
import SideBar from '../../components/sidebar/SideBar';
import '../send-notification/sendnotification.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress } from '@mui/material';

const ChangePassword = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [changePassword, setChangePassword] = useState({
    password: '',
    confirmpassword: '',
  });

  const bearerToken = localStorage.getItem('session-token');
  const userId = localStorage.getItem('user-id');

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setChangePassword((values) => ({
      ...values,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      changePassword.password === '' ||
      changePassword.confirmpassword === ''
    ) {
      setError(true);
    } else {
      try {
        if (changePassword.password !== changePassword.confirmpassword) {
          showErrorPassword();
        } else {
          event.target.reset();
          handleToggle();
          const pw = {
            password: changePassword.confirmpassword,
          };

          const password = await axios.post(
            `${process.env.REACT_APP_API_URL}/admin/${userId}/password/reset`,
            pw,
            {
              headers: {
                Authorization: `Bearer ${bearerToken}`,
                BasicAuth: process.env.REACT_APP_BASIC_AUTH,
              },
            }
          );
          if (password) {
            const timer = setTimeout(() => {
              handleClose();
              showPassword();
            }, 1000);
            return () => clearTimeout(timer);
          } else {
            const timer = setTimeout(() => {
              console.log('Something went wrong', error);
              handleClose();
              showErrorMessage();
            }, 1000);
            return () => clearTimeout(timer);
          }
        }
      } catch (error) {
        const timer = setTimeout(() => {
          console.log('Something went wrong', error);
          handleClose();
          showErrorMessage();
        }, 1000);
        return () => clearTimeout(timer);
      }
    }
  };

  const showPassword = () => {
    toast.success('Password Changed Successfully', {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showErrorPassword = () => {
    toast.error('Password does not matched ', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const showErrorMessage = () => {
    toast.error('Something went wrong try again later ', {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };
  return (
    <div className="sendnotify">
      <SideBar />
      <div className="sendnotifyContainer">
        <Navbar />
        <div className="p-3">
          <h4 className="mt-1"> Change Password</h4>
          <div className="changing border   mt-4 p-3 rounded m-auto col-12">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 col-6">
                <label>New Password</label>
                <input
                  type="text"
                  name="password"
                  className="form-control "
                  placeholder="Enter New Password"
                  onChange={handleChange}
                />
                {error && changePassword.password === '' ? (
                  <label style={{ color: '#FF0033' }}>
                    Password should not be empty
                  </label>
                ) : (
                  ''
                )}
              </div>

              <div className="mb-3 col-6">
                <label>Confirm Password</label>
                <input
                  type="text"
                  name="confirmpassword"
                  className="form-control "
                  placeholder="Re-Enter New Password"
                  onChange={handleChange}
                />
                {error && changePassword.confirmpassword === '' ? (
                  <label style={{ color: '#FF0033' }}>
                    New Password Field Required
                  </label>
                ) : (
                  ''
                )}
              </div>

              <div className="d-grid col-2">
                <button type="submit" className="btn btn-primary">
                  Update
                </button>
                <Backdrop
                  sx={{
                    color: '#fff',

                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={open}
                  onClick={handleClose}
                >
                  <div
                    className=" d-flex py-4  bg-dark flex-column justify-content-center"
                    style={{ width: '240px', height: '90px' }}
                  >
                    <CircularProgress sx={{ margin: 'auto' }} color="inherit" />
                    <h6 className="m-auto">Updating Password...</h6>
                  </div>
                </Backdrop>
                <ToastContainer
                  position="top-right"
                  autoClose={2000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  draggable
                  theme="light"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
